import React from "react";
import { Typography } from "antd";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import BHero from "../../components/Hero/b-hero";
import {
  iaapaHero,
  iaapaWhiteGlove,
  iaapaBoothData,
  showRotarySection,
} from "../../data/events/iaapa-data";
import WhiteGlove from "../../components/WhiteGlove/WhiteGlove";
import heroBg from "../../images/hero-images/nightclub-bar-event-hero.png";
import RotarySection from "../../components/RotarySection";
import EventForm from "../../components/EventsComps/EventForm";
import BoothCTA from "../../components/EventsComps/BoothCTA";
import Heading from "../../components-v2/Base/HeadingBuilder";

const whiteGloveImport = "event-white-glove.png";
const { Title } = Typography;

const customHero = () => {
  return (
    <>
      <Heading
        level={1}
        injectionType={2}
        className="hero-b__title text-center"
        style={{ fontSize: 42 }}
      >
        Join SpotOn at the IAAPA Expo
        <span className="blue_dot">.</span>
        <br />
        <p
          style={{
            color: "#1769FF",
            fontSize: "32px",
            marginBottom: "24px",
            lineHeight: 1.5,
          }}
        >
          Orlando / November 16-19, 2021{" "}
        </p>
        <p
          className="text-center"
          style={{
            color: "#748093 !important",
            fontSize: 20,
            marginTop: "24px",
            fontWeight: 400,
            lineHeight: 1.5,
          }}
        >
          Find SpotOn at the IAAPA Expo at booth #3354, November 16-19, to get
          an up-close look at our best-in-class software and point-of-sale
          technology for the leisure and attractions industry. Or click below to
          get a demonstration.
        </p>
      </Heading>
    </>
  );
};

const iaapa = () => {
  return (
    <Layout isLanding phoneNumber="+18882246506" phoneText="(888) 224-6506">
      <SEO
        title="IAAPA Expo | SpotOn | Enterprise"
        description="Join SpotOn at the IAAPA Expo at booth #3354 on Nov. 16-19 to get an up-close look at our best-in-class software and point-of-sale technology for the leisure and attractions industry."
        image={`https://spoton-website-staging.netlify.app/${heroBg}`}
      />
      <BHero
        sectionData={iaapaHero}
        heroBg="iaapa-hero.png"
        clearBg
        customHeroContent={customHero()}
      />
      <div style={{ marginTop: "64px" }} />
      <WhiteGlove
        sectionData={iaapaWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <BoothCTA sectionData={iaapaBoothData} />
      <RotarySection sectionData={showRotarySection} />
      <EventForm
        id="demo"
        leadType="tradeshow"
        formId="3802e416-0f35-43ee-936a-1b1cc7037886"
        thankYouPath="/events/thank-you"
      />
      {/*      <Articles
        sectionData={iaapaArticles}
        blogEntries={tempData}
        cornerStone={false}
      /> */}
    </Layout>
  );
};

export default iaapa;
